/* LOGS */

.ant-descriptions-item-label {
    white-space: nowrap;
}

.ant-descriptions-item-content pre {
    white-space: pre-wrap;
}

/* TABLE */

.ant-empty-normal {
    margin: 5px 0;
}

.ant-space-vertical {
    width: 100%
}

.ant-table.ant-table-small {
    font-size: 12px;
}

td.ant-table-cell a {
    font-weight: bold;
}

.ant-form-item {
    margin-bottom: 10px;
}

.ant-btn-success {
    color: #fff;
    background: #009607;
    border-color: #009607;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary.fake-button {
    cursor: default !important;
    background: #1890ff !important;
    border-color: #1890ff !important;
}

.ant-picker, .ant-input-number {
    width: 100%;
}

.ant-tooltip-inner {
    width: max-content;
}

.ant-notification-notice {
    width: max-content;
    min-width: 400px;
    max-width: 600px;
}

.ant-notification .ant-list-item {
    padding: 3px 16px 3px 0;
}