code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.header {
  background-color: #CCCCCC;
  padding: 10px;
}

/* .footer {
   background-color: #666666;
   padding: 10px;
} */

.defaultRow {
  padding: 10px;
}

.defaultCol {
  margin-left: 15px;
}

/* MENU */
#sidebar-menu ul li.menu-title {
  color: #ffffff !important;
}

#sidebar-menu ul li a.menu-indent {
  padding-left: 30px !important;
}

/* TABLE */
.margin-0-I .ant-table {
  margin: 0 !important;
}

.ant-table .column-image {
  text-align: center;
  vertical-align: middle;
}
.ant-table .column-image img {
  max-width:75px;
  max-height:75px;
  width: auto;
  height: auto;
}

/* TABLE SORTING */

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}

a.link-cell {
  display: block;
  text-decoration: underline !important
}

/* PRODUCT GALLERY */

.product_gallery_thumb {
  width: 85px;
  padding: 5px;
}

.product_gallery_name {
  font-weight: bold;
}

.product_gallery_action {
  max-width: 100px;
  text-align: right;
}

/* LOGS */

.ant-descriptions-item-label {
  white-space: nowrap;
}

.ant-descriptions-item-content pre {
  white-space: pre-wrap;
}

/* TABS */
.card-container p {
  margin: 0;
}

.card-container>.ant-tabs-card .ant-tabs-content {
  min-height: 120px;
  margin-top: -16px;
}

.card-container>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
  padding: 16px;
  background: #fff;
}

.card-container>.ant-tabs-card>.ant-tabs-nav::before {
  display: none;
}

.card-container>.ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}

.card-container>.ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}

#components-tabs-demo-card-top .code-box-demo {
  padding: 24px;
  overflow: hidden;
  background: #f5f5f5;
}

[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-content {
  min-height: 120px;
  margin-top: -8px;
}

[data-theme='dark'] .card-container>.ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}

[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}

[data-theme='dark'] .card-container>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
  background: #141414;
}

[data-theme='dark'] .card-container>.ant-tabs-card .ant-tabs-tab-active {
  background: #141414;
  border-color: #141414;
}